import {
  Anchor,
  BorderBox,
  Box,
  Divider,
  FlexBox,
  Heading,
  Text,
} from '@trmediaab/zebra';
import Link from 'next/link';
import PropTypes from 'prop-types';

import Arrow from '~/icons/Arrow';

const Card = ({ title, children, footer, ...props }) => (
  <BorderBox
    px={[3, null, 4]}
    pt={['3,5', null, 4]}
    pb={[3, null, 4]}
    bg="white"
    boxShadow="1"
    borderTopLeftRadius={[2, null, 3]}
    borderTopRightRadius={[2, null, 3]}
    borderBottomLeftRadius={[2, null, 3]}
    borderBottomRightRadius={[2, null, 3]}
    overflow="hidden"
    {...props}
  >
    {title && (
      <Heading.h4 as="h2" mb="3,5">
        {title}
      </Heading.h4>
    )}
    {children}
    {footer != null && (
      <>
        <Divider my="3,5" />
        {footer}
      </>
    )}
  </BorderBox>
);

Card.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  footer: PropTypes.node,
};

const FooterLink = ({ href, title }) => (
  <Anchor as={Link} href={href}>
    <FlexBox>
      <Text.inline lineHeight="1.4">
        <Arrow direction="right" size="20px" mr="2" />
      </Text.inline>
      <Box.inline>{title}</Box.inline>
    </FlexBox>
  </Anchor>
);

FooterLink.propTypes = {
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

Card.footerLink = FooterLink;

export default Card;
