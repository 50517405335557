import { Box, Button, FlexBox, Heading, Text } from '@trmediaab/zebra';
import Link from 'next/link';
import PropTypes from 'prop-types';

import Arrow from '~/icons/Arrow';

const PageHeader = ({
  title,
  subTitle,
  showBackLink = true,
  backLinkText = 'Tillbaka',
  backLinkHref = '/',
}) => (
  <Box as="header">
    <FlexBox alignItems="center">
      <Heading.h1
        mr="2"
        css={`
          hyphens: auto;
        `}
      >
        {title}
      </Heading.h1>
      {showBackLink && (
        <Button
          as={Link}
          href={backLinkHref}
          bc="secondary"
          ml="auto"
          fontWeight="bold"
        >
          <Arrow size="18px" mr="2" /> {backLinkText}
        </Button>
      )}
    </FlexBox>
    {subTitle != null && (
      <Text mt="3" fontSize="3" color="text.light">
        {subTitle}
      </Text>
    )}
  </Box>
);

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.node,
  showBackLink: PropTypes.bool,
  backLinkText: PropTypes.string,
  backLinkHref: PropTypes.string,
};

export default PageHeader;
