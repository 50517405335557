import { Done as MaterialDone } from '@styled-icons/material';
import { propType as styledSystemPropType } from '@styled-system/prop-types';
import { BorderBox } from '@trmediaab/zebra';
import { createStyledIcon } from '@trmediaab/zebra-icons';
import PropTypes from 'prop-types';

const DoneIcon = createStyledIcon(MaterialDone);

const Done = ({ strokeWidth = '1px', sx, ...props }) => (
  <DoneIcon
    sx={{
      '> :last-child': {
        stroke: 'currentColor',
        strokeWidth: strokeWidth,
      },
      ...sx,
    }}
    {...props}
  />
);

Done.propTypes = {
  strokeWidth: styledSystemPropType,
  sx: PropTypes.object,
};

export const DoneRound = ({ strokeColor = 'green', strokeWidth, ...props }) => (
  <BorderBox.inline
    borderRadius="circle"
    bg="white"
    css={`
      box-sizing: content-box;
      line-height: 1;
    `}
    p="2px"
    {...props}
  >
    <Done size="inherit" color={strokeColor} strokeWidth={strokeWidth} />
  </BorderBox.inline>
);

DoneRound.propTypes = {
  strokeColor: styledSystemPropType,
  strokeWidth: styledSystemPropType,
};

export default Done;
