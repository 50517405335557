import { ArrowBack } from '@styled-icons/material';
import { createStyledIcon } from '@trmediaab/zebra-icons';
import PropTypes from 'prop-types';

const getRotation = direction => {
  switch (direction) {
    case 'up':
      return 'rotate(90deg)';
    case 'down':
      return 'rotate(-90deg)';
    case 'right':
      return 'rotate(180deg)';
    default:
      return;
  }
};

const SystemArrow = createStyledIcon(ArrowBack);

const Arrow = ({ direction = 'left', ...props }) => (
  <SystemArrow
    sx={{
      transform: getRotation(direction),
    }}
    {...props}
  />
);

Arrow.propTypes = {
  direction: PropTypes.oneOf(['up', 'right', 'down', 'left']),
};

export default Arrow;
