import { Box, Heading, Text } from '@trmediaab/zebra';
import { cloudinary } from '@trmediaab/zebra-utils';
import PropTypes from 'prop-types';

const orgsMap = {
  trmedia_ab: {
    heading: 'Sparade betalkort för TR Media',
    text: 'Används för Jokersystemet Sverige, Travronden, Travögat, Spelvärde, Travfakta och Storavinster',
    logo: 'brands/logos/si3okwhx7yghsm0fknwo',
  },
  jokersystemet_as: {
    heading: 'Sparade betalkort för Jokersystemet Norge',
    text: 'Används för Jokersystemet Norge',
    logo: 'brands/logos/i6driejcmhm6cqtwc9jg',
  },
};

const toTemplateAreas = areas => areas.map(area => `"${area}"`).join(' ');

const mobileAreas = ['heading image', 'heading image', 'text text'];
const desktopAreas = ['heading image', 'text image'];

const OrganizationHeader = ({ organization }) => {
  const { heading, text } = orgsMap[organization];

  return (
    <Box
      display="grid"
      sx={{
        gridTemplateAreas: [
          toTemplateAreas(mobileAreas),
          null,
          toTemplateAreas(desktopAreas),
        ],
        gridColumnGap: '2',
        gridRowGap: '2',
      }}
    >
      <Heading.h4 as="h2">{heading}</Heading.h4>
      <Text sx={{ gridArea: 'text' }} maxWidth={[null, null, '400px']}>
        {text}
      </Text>
      <Box
        as="img"
        src={cloudinary.getUrl(orgsMap[organization].logo, 150)}
        alt={organization}
        sx={{ gridArea: 'image', ml: 'auto' }}
      />
    </Box>
  );
};

OrganizationHeader.propTypes = {
  organization: PropTypes.oneOf(Object.keys(orgsMap)),
};

export default OrganizationHeader;
