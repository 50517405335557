export default function isNorwegianHost() {
  if (
    ['minasidor-no.trdev.se', 'minesider.trmedia.no'].includes(
      window.location.hostname,
    )
  ) {
    return true;
  } else if (
    (process.env.NODE_ENV === 'development' ||
      process.env.VERCEL_ENV === 'preview') &&
    process.env.NEXT_PUBLIC_DEV_BUILD_COUNTRY === 'no'
  ) {
    return true;
  } else {
    return false;
  }
}
