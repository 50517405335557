import isNorwegianHost from './isNorwegianHost';

/*
    Returns an array of organizations based on 
    the users history with the organization or domain.

    1. Customer has made purchases in both stores: show both organizations
    2. Customer has made purchases only in the swedish store: show SE organization
    3. Customer has made purchases only in the norwegian store: show NO organization
    4. Customer has no purchases and is on minasidor.trmedia.se: show SE organization
    5. Customer has no purchases and is on minesider.trmedia.no: show NO organization
*/
export default function availableOrganizations(data) {
  const { subscriptions: activeSubscriptions } = data.getSubscriptionsByUser;

  const hasActiveSubs = activeSubscriptions.length > 0;
  const isNoDomain = isNorwegianHost();

  const showTrMediaAb =
    activeSubscriptions.some(sub => sub.product.site !== 'jokersystemet_no') ||
    (!hasActiveSubs && !isNoDomain);

  const showJokerNo =
    activeSubscriptions.some(sub => sub.product.site === 'jokersystemet_no') ||
    (!hasActiveSubs && isNoDomain);

  return [
    showTrMediaAb && 'trmedia_ab',
    showJokerNo && 'jokersystemet_as',
  ].filter(Boolean);
}
