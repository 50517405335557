import { Position, Rhythm } from '@trmediaab/zebra';
import PropTypes from 'prop-types';
import { Children, cloneElement } from 'react';

const CardStack = ({ children, stack = 'loose' }) => {
  if (stack === 'loose') {
    return <Rhythm between="6">{children}</Rhythm>;
  }

  const numChildren = Children.count(children);

  let idx = 0;

  return (
    <Position.relative>
      {Children.map(children, child => {
        if (child == null) {
          return child;
        }

        let props = {
          sx: {
            position: 'relative',
            zIndex: numChildren - idx,
            ...child.props.sx,
          },
        };

        if (idx < numChildren - 1) {
          props = {
            ...props,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          };
        }

        if (idx > 0) {
          props = {
            ...props,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          };
        }

        idx++;

        return cloneElement(child, props);
      })}
    </Position.relative>
  );
};

CardStack.propTypes = {
  children: PropTypes.node.isRequired,
  stack: PropTypes.oneOf(['loose', 'tight']),
};

export default CardStack;
